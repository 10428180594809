@media only screen and (min-width: 768px) {
    .Home .ui.container {
        width: 700px;
        max-width: 700px;
    }

    .Home nav.ui.container .ui.header {
        float: left;
        margin-top: 12px;
    }

    .Home nav.ui.container .ui.inverted.compact.menu {
        float: right;
    }
}

.Home .ui.inverted.vertical.center.aligned.segment {
    padding-top: 36px;
}

.Home nav.ui.container .ui.inverted.compact.menu {
    background-color: rgb(51, 51, 51);
}

.Home nav.ui.container .ui.inverted.compact.menu .item {
    padding-bottom: 0;
}

.Home nav.ui.container .ui.inverted.compact.menu .item {
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 14px;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 0;
}

.Home nav.ui.container .ui.inverted.compact.menu .active.item {
    background-color: inherit;
    border-bottom-color: white;
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

.Home .ui.inverted.vertical.segment {
    height: 100vh;
    background-color: rgb(51, 51, 51);
}

.Home .ui.content.container {
    height: 300px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.Home .ui.content.container h1.header {
    font-size: 36px;
}

.Home .ui.content.container h2.header {
    font-size: 24px;
    margin-top: 0;
}

.Home .ui.content.container p {
    font-size: 21px;
    line-height: 1.6;
    font-weight: 300;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.Home .ui.content.container .button a {
    color: inherit;
}

.Home .ui.inverted.vertical.segment footer.ui.segment {
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: gray;
}

.Home .ui.inverted.vertical.segment footer.ui.segment a {
    color: white;
}
